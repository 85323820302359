'use client';
import { CardItemShopEntityMobile } from '@entities/mobile/cards';
import { ContentWidgetItemsBlockProps } from '@widgets/desktop/content/ui/content';
import React from 'react';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Label } from '@/shared/ui';

import styles from './list-cards-wrappers.module.scss';

export const PopularItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'yellow'}
                  icon={'graph-up'}
                  variant={'primary'}
                >
                  Popular items
                </Label>
                <SeeAllButton
                  href={'/shop?sort=popular'}
                  variant={'secondary'}
                />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};
export const CloseInValueItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'yellow'}
                  icon={'graph-up'}
                  variant={'primary'}
                >
                  Items close in value
                </Label>
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};

export const ExclusiveItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'black'}
                  icon={'star-circle'}
                  variant={'primary'}
                >
                  Exclusive Items
                </Label>
                <SeeAllButton
                  href={'/shop?sort=popular'}
                  variant={'secondary'}
                />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};
export const HotSalesItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ItemsBlock
            items={items}
            headerSlot={
              <>
                <Label
                  labelVariant={'red'}
                  icon={'fire-square-red'}
                  variant={'primary'}
                >
                  Hot Sales
                </Label>
                <SeeAllButton
                  href={'/shop?sort=popular'}
                  variant={'secondary'}
                />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};

export const ItemsBlock = ({
  headerSlot,
  items,
}: ContentWidgetItemsBlockProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <div className={styles['content-widget__items-block']}>
      {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
      <div className={styles['items']}>
        {items?.map((item, idx) => {
          return (
            <CardItemShopEntityMobile
              key={`card-${idx}-${item.realName}-${item?.img}`}
              item={item}
            />
          );
        })}
      </div>
    </div>
  );
};
